// routing
import Routes from 'routes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// Auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';

const App = () => (
  <ThemeCustomization>
    {/* RTL layout */}
    <RTLLayout>
      <NavigationScroll>
        <AuthProvider>
          <>
            <Routes />
            <Snackbar />
          </>
        </AuthProvider>
      </NavigationScroll>
    </RTLLayout>
  </ThemeCustomization>
);

export default App;
